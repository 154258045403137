import {ReactElement} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {Link, useLocation} from "react-router-dom";
import {HiOutlineNewspaper} from "react-icons/hi";
import {RiVipLine} from "react-icons/ri";
import {AiOutlineHeart, AiOutlineMenu, AiOutlineSearch} from "react-icons/ai";
import {FaChevronLeft} from "react-icons/fa";
import classNames from "classnames";
import {toggleSidebar} from "@/redux/meta/metaActions";
import {useNavigate} from "react-router";
import {isIOSApp} from "@/utils/isGoNative";
import {MdEmojiPeople, MdOutlineShoppingBag} from "react-icons/md";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import { DEVOUR_RESTAURANT_ID } from "@/pages/restaurants/RestaurantPage";
import { isDesktop, isMobile, isTablet } from "react-device-detect";

function DevourStickyFooter(): ReactElement {
    const location = useLocation();
    const dispatch = useDispatch();
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const sidebarOpen = useSelector((store: IStore) => store.metaStore.sidebarOpen);
    const bottomNavHidden = useSelector((store: IStore) => store.metaStore.isBottomNavHidden);
    const navigate = useNavigate();
    const isModalActive = useSelector((store: IStore) => store.metaStore.activeModals);

    const getIsOnLandingPage = () => {
        return (location.pathname.includes("/restaurants") || location.pathname === "/") && !fullToken;
    };

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        if (getIsOnLandingPage()) {
            return;
        }
        dispatch(toggleSidebar(false));
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }

    }

    if (isModalActive !== 0) {
        return null;
    }

    return (
        <>
            {!bottomNavHidden &&
            <>
				    {isIOSApp() &&
				    <button
						    className="devour-ios-back-button"
						    onClick={() => {
						        navigate(-1);
						    }}
				    >
						    <FaChevronLeft className="devour-ios-back-button_icon"/>
				    </button>
                }
				    <div className="devour-sticky-footer">
				        <div className="devour-sticky-footer_content">
				            <button
				                onClick={handleOrderFoodRouteForAuthedUser}
				                className={classNames("devour-sticky-footer_link", {
				                    "is-selected": getIsOnLandingPage() && !sidebarOpen,
				                })}
				            >
				                <AiOutlineSearch/>
				                <span className="devour-sticky-footer_link_text">
							Search
				                </span>
				            </button>

				            {fullToken &&
				            <Link
								    to="/favorites"
								    onClick={() => dispatch(toggleSidebar(false))}
								    className={classNames("devour-sticky-footer_link", {
								        "is-selected": location.pathname.includes("/favorites") && !sidebarOpen,
								    })}
				            >
								    <AiOutlineHeart/>
								    <span className="devour-sticky-footer_link_text">
								Favorites
								    </span>
				            </Link>
                        }

				            {fullToken && (isDesktop || isTablet) &&
				            <Link
								    to="/menu-orders"
								    onClick={() => dispatch(toggleSidebar(false))}
								    className={classNames("devour-sticky-footer_link", {
								        "is-selected": location.pathname.includes("/menu-orders") && !sidebarOpen,
								    })}
				            >
								    <HiOutlineNewspaper/>
								    <span className="devour-sticky-footer_link_text">
								Orders
								    </span>
				            </Link>
                        }

				            <Link
				                to="/go-vip-dashboard"
				                onClick={() => dispatch(toggleSidebar(false))}
				                className={classNames("devour-sticky-footer_link", {
				                    "is-selected": location.pathname.includes("/go-vip-dashboard") && !sidebarOpen,
				                })}
				            >
				                <RiVipLine/>
				                <span className="devour-sticky-footer_link_text">
							GoVIP
				                </span>
				            </Link>

				            {!fullToken &&
				            <Link
								    to="/gofriends"
								    onClick={() => dispatch(toggleSidebar(false))}
								    className={classNames("devour-sticky-footer_link", {
								        "is-selected": location.pathname.includes("/gofriends") && !sidebarOpen,
								    })}
				            >
								    <MdEmojiPeople/>
								    <span className="devour-sticky-footer_link_text">
									GoFriends
								    </span>
				            </Link>
				            }

				            <Link
								    to={`/restaurants/${DEVOUR_RESTAURANT_ID}`}
								    onClick={() => dispatch(toggleSidebar(false))}
								    className={classNames("devour-sticky-footer_link", {
								        "is-selected": location.pathname.includes(`/restaurants/${DEVOUR_RESTAURANT_ID}`) && !sidebarOpen,
								    })}
				            >
								    <MdOutlineShoppingBag/>
								    <span className="devour-sticky-footer_link_text">
									LootShop
								    </span>
				            </Link>
				            <button
				                onClick={() => dispatch(toggleSidebar(!sidebarOpen))}
				                className={classNames("devour-sticky-footer_button", {
				                    "is-selected": sidebarOpen,
				                })}
				            >
				                <AiOutlineMenu/>
				                <span className="devour-sticky-footer_link_text">
							Menu
				                </span>
				            </button>
				        </div>
				    </div>
            </>
            }
        </>
    );
}

export default DevourStickyFooter;
