import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import OverwolfGoVipAccountLevel from "@/pages/overwolf/components/OverwolfGoVipAccountLevel";
import OverwolfGoVipRewards from "@/pages/overwolf/components/OverwolfGoVipRewards";
import FrameButton from "@/components/buttons/FrameButton";
import {useEffect, useState} from "react";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import {toggleSidebar} from "@/redux/meta/metaActions";
import OverwolfIndustryPassPromoBanner from "@/pages/overwolf/components/OverwolfIndustryPassPromoBanner";
import useGetOverwolfArt, {OverwolfArtType} from "@/hooks/useGetOverwolfArt";
import {ThemePreference} from "@/types/Theme";
import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import OverwolfDiscordBanner from "@/pages/overwolf/components/OverwolfDiscordBanner";
import OWAnnouncementModal from "@/components/devourPlay/OWAnnouncementModal";
import {OverwolfGoVipSummary} from "./components/OverwolfGoVipSummary";
import classNames from "classnames";
import useWindowSize from "@/hooks/useWindowSize";
import {SiteConfigurationKey, SiteConfigurationsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

export default function OverwolfGoVip() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const {getOverwolfArt} = useGetOverwolfArt(OverwolfArtType.bannerImage);
    const dispatch = useDispatch();
    const {setTheme} = useThemePreference();
    const windowSize = useWindowSize()[0];
    const isInGameView = windowSize < 400;
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
    const [announcementModalConfig, setAnnouncementModalConfig] = useState(null);

    // Close the sidebar menu if open
    useEffect(() => {
        void getAnnouncementModalConfig();
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);
        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    async function getAnnouncementModalConfig() {
        try {
            const modalConfig = await new SiteConfigurationsApi(getConfig(fullToken)).getSiteConfiguration({
                configKey: SiteConfigurationKey.OWANNOUNCEMENTMODAL,
            });
            setAnnouncementModalConfig(modalConfig);
            // Show announcement modal if it is within a valid date (date now is between start and end date)
            setShowAnnouncementModal(modalConfig.isWithinValidDate);
        } catch (e) {
            console.error("Error fetching announcement modal config", e);
        }
    }

    return (
        <>
            {/* OVERWOLF FEATURE ANNOUNCEMENT MODAL */}
            {announcementModalConfig && <OWAnnouncementModal
                isOpen={showAnnouncementModal}
                onClose={() => setShowAnnouncementModal((prev) => !prev)}
                version={announcementModalConfig.data?.version || ""}
                title={announcementModalConfig.title}
                link={announcementModalConfig.url}
                description={announcementModalConfig.description}
                video={announcementModalConfig.data?.video || ""}
                image={announcementModalConfig.asset?.url || ""}
                size={"xs1"}
            />
            }

            <div
                className="overwolf-govip"
                style={{
                    backgroundImage: `url(${getOverwolfArt})`,
                }}
            >
                <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>

                <div className="overwolf-govip_content">
                    <div className={classNames({
                        "overwolf-govip_content_summary": !!fullToken,
                    })}>
                        <OverwolfGoVipAccountLevel/>
                        {fullToken && <OverwolfGoVipSummary/>}
                    </div>

                    <OverwolfIndustryPassPromoBanner/>

                    <OverwolfGoVipRewards/>
                </div>

                {!fullToken && <FrameButton
                    size="large"
                    className="overwolf-govip_login-btn overwolf-govip_button_purple-blue-gradient-shadow"
                    onClick={() => setIsLoginModalOpen(true)}
                >
                    Login or Sign up
                </FrameButton>
                }
            </div>
            {fullToken && <OverwolfDiscordBanner/>}
        </>

    );
}
